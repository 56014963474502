//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import { Login, Banner, ShopInfo } from "@/components";

export default {
    components: {
        Login,
        Banner,
        ShopInfo
    },
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            // config(state) {
            //     return state.base.configInfo;
            // },
            chkAcctExistStat() {
                return this.$store.state.base.chkAcctExistStat;
            },
        }),
    },
    data() {
        return {};
    },
    created() { },
    mounted() { },
    methods: {},
};
